import { parseJSON } from 'jquery';
import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const Register = (props) => {
    const [formValues, setValues] = useState({
        username: '',
        email: '',
        password: '',
        rememberme: true
    });

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        password: '',
        apiresponse: ''
    });

    const { user, checkLogin } = useContext(UserContext);

    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setErrors({ ...errors, apiresponse: '' });

        if (e.target.name == 'username') {
           validateUsername(value);
        }
        if (e.target.name == 'email') {
            validateEmail(value);
        }
        if (e.target.name == 'password') {
            validatePassword(value);
        }

        setValues({ ...formValues, [e.target.name]: value });
    }

    const validateUsername = (value) => {
        if (value.length == 0) {
            setErrors({ ...errors, username: 'user name required!' });
            return false;
        }
        else {
            setErrors({ ...errors, username: '' });
            return true;
        }
    }

    const validateEmail = (value) => {
        if (value.length == 0) {
            setErrors({ ...errors, email: 'email required!' });
            return false;
        }
        else {
            setErrors({ ...errors, email: '' });
            return true;
        }
    }

    const validatePassword = (value) => {
        if (value.length < 6) {
            setErrors({ ...errors, password: 'needs to be 6 characters or more!' });
            return false;
        }
        else {
            setErrors({ ...errors, password: '' });
            return true;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //first round validation
        let valid = validateUsername(formValues.username) &&
                    validateEmail(formValues.email) &&
                    validatePassword(formValues.password);

        if (valid)
        {
            //call to register
            RegisterUser();
        }
    }

    const RegisterUser = async () => {
        var registerModel = formValues;

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(registerModel)
        };

        const response = await fetch('api/authenticate/register', postOptions);

        if (response.status == 200) {

            //const userInfo = {
            //    username: formValues.username,
            //    isLoggedIn: true
            //};
            //updateUser(userInfo);
            //let isCookieThere = await Utils.IsLoggedIn();

            //localStorage.setItem('username', formValues.username);
            //localStorage.setItem('isLoggedIn', true);
            await checkLogin();

            props.history.push('/');
        }
        else {
            response.text().then(value => {
                //alert(parseJSON(value)?.message);
                let err = parseJSON(value)?.message;
                if (err == null) {
                    err = 'Register Failed. Make sure of the following:'
                        + '\n-Username, Email address, password are required.'
                        + '\n-Username should only have alphanumeric characters and underscores.'
                        + '\n-Password should be at least 6 characters.';
                }
                setErrors({ ...errors, apiresponse: err });
            }
            );
        }
    }



    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    <h4>Register</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Enter User Name"
                                onChange={handleInputChange}>
                            </input>
                            <span style={{ color: "red" }}>{errors.username}</span>
                        </div>
                        <div className="form-group mt-3">
                            <label>Email Address:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                placeholder="Enter Email"
                                onChange={handleInputChange}>
                            </input>
                            <span style={{ color: "red" }}>{errors.email}</span>
                        </div>
                        <div className="form-group mt-3">
                            <label>Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Enter password"
                                onChange={handleInputChange}>
                            </input>
                            <span style={{ color: "red" }}>{errors.password}</span>
                        </div>
                        <div className="form-check mt-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="rememberme"
                                checked={formValues.rememberme}
                                onChange={handleInputChange}>
                            </input>
                            <label className="form-check-label">Remember Me</label>
                        </div>
                        <div className="container mt-3">
                            <div className="d-flex justify-content-around">
                                <button type="submit" className="btn btn-primary">Submit</button>
                                <Link className="btn btn-secondary" to={{ pathname: "/" }}>Cancel</Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ color: "red" }}>{errors.apiresponse}</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}