import React, { useEffect, useState, useContext } from 'react';
import { Rating } from './Rating';
import { UserContext } from './authorization/UserProvider';

export const Comment = (props) => {
    const rating = props.rating;

    const ratingNum = rating.rating;
    const username = rating?.username ?? 'anonymous';
    const comment = rating?.comment ?? '';
    const timestamp = rating?.timestamp;

    //convert to just the date
    let date = '';
    if (timestamp != null)
        date = new Date(timestamp)?.toDateString();

    const { user } = useContext(UserContext);

    const [showEditButton, setShowEditButton] = useState(false);

    useEffect(() => {
        if (user != null && user.username === username)
            setShowEditButton(true);
    }, []);

    return (
        <div className="card bg-light">
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <span className="mr-2"><b>{username}</b></span>
                        <br />
                        <span><i>{date}</i></span>
                    </div>
                    {showEditButton &&
                        <button className="btn btn-secondary btn-sm" onClick={() => props.onEditRatingClickFromComments(rating)}>
                            Edit    <i className="fas fa-edit"></i>
                        </button>
                    }
                </div>
                <Rating rating={ratingNum} />
                <br />
                {comment}
            </div>
        </div>
    );
}