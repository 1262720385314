import React, { Component, useEffect, useState, useContext} from 'react';
import { Map } from './Map.js';
import { Login } from './authorization/Login';
import { useOnlineStatus } from './OnlineStatusProvider.js';
import { UserContext } from './authorization/UserProvider';
import { LoadingGraphic } from './LoadingGraphic.js';

export const Home = (props) => {
    const { user, isLoggingIn } = useContext(UserContext);
    //const isOnline = useOnlineStatus();
    const isOnline = true;
    const loginSuccess = () => {
    }

    //const isLoggingIn = true;
    //const user = null;
    if (isOnline) {
        if (user != null && user.isLoggedIn) {
            return (
                <div className='position-relative' style={{ height: '100%' }}>
                    <Map />
                </div>
            );
        }
        else {
            if (isLoggingIn) {
                return (
                    <div className='position-relative' style={{ height: '100%' }}>
                        <LoadingGraphic />
                    </div>
                );
            }
            else {
                return <Login />;
            }
        }
    }
    else {
        return (
            <div className='position-relative' style={{ height: '100%' }}>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <i className="fas fa-poo fa-3x"></i>
                </div>
                <div className="text-center mt-3">
                    Oops, looks like you are offline
                </div>
            </div>
        );
    }
}
