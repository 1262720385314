import React, { Component } from 'react';
import poocoffee from '../symbols/poocoffee.png';
import '../coffeesteam.css';

export const LoadingGraphic = (props) => {
    return (
        <div style={{ height: '100%', backgroundColor: '#cddae5'}} className="d-flex align-items-center">
            <div className="container text-center position-relative" style={{width: 'auto'}} >
                <div className="steam" id="steam1"> </div>
                <div className="steam" id="steam2"> </div>
                <div className="steam" id="steam3"> </div>
                <div className="steam" id="steam4"> </div>
                <img className="position-relative" style={{zIndex: '1'}} src={poocoffee} />
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status" style={{opacity: '20%'}}></div>
                    <span>    Loading...</span>
                </div>
            </div>
        </div>
    );
}