import React, { useContext, useEffect, useState } from 'react';
import { NotificationContext } from './NotificationProvider';

export const NotificationButton = (props) => {
    const { notifications } = useContext(NotificationContext);
    const [listOpen, setListOpen] = useState(false);

    const hasNotifications = notifications != null && notifications.length > 0;

    const newCount = notifications?.filter(n => {
        if (n.seen == false) {
            return true;
        }

        return false;
    }).length;

    const onClick = () => {
        setListOpen(!listOpen);
    }

    if (!hasNotifications)
        return null;
    else
        return (
            <div style={{
                position: 'relative',
                display: 'inline-block',
                verticalAlign: 'middle'
            }}>
            <a onClick={onClick}>
                <Bell />
                {newCount > 0 &&
                    <span style={{
                        position: 'absolute',
                        top: '-7px',
                        right: '-7px',
                    }} className='badge bg-primary rounded-pill'>{newCount}</span>  
                }        
            </a> 
            {
            listOpen &&
            <ul className="list-group" style={{ position: 'absolute', top: '30px', minWidth: '240px', maxWidth: '100%', zIndex: '50000' }}>
                {Object.values(notifications).map((notificationViewModel) =>        
                    <NotificationItem key={notificationViewModel?.notificationId} notification={notificationViewModel} onClick={() => setListOpen(false)} />
                )}
            </ul>
            }
            </div>
        );
}

const Bell = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dee2e6ab" viewBox="0 0 448 512">
            <path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" />
        </svg>
    );
}

const NotificationItem = (props) => {
    const notification = props.notification;
    const seen = notification?.seen ?? false;
    const [bgColor, setBgColor] = useState('');
    const { OpenNotificationPopup } = useContext(NotificationContext);

    useEffect(() => {
        if (seen) {
            setBgColor('white');
        } else {
            setBgColor('#cddae5');
        }
    }, []);

    const onNotificationClick = async () => {
        setBgColor('white');
        OpenNotificationPopup(notification);
        props.onClick();
    }

    return (
        <a onClick={onNotificationClick} className="list-group-item list-group-item-action" style={{ backgroundColor: bgColor }}>
            <span className="badge bg-primary rounded-pill"></span>
            <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1">{notification.title}</h6>
                <small>{new Date(notification.timestamp).toLocaleDateString()}</small>
            </div>
            <span className="d-inline-block text-truncate" style={{ maxWidth: '200px' }}>
                {notification.message}
            </span>
        </a>
    );
}