import React, { Component } from 'react';

export const ExternalSigninButton = (props) => {
    const height = props.height + 'px';
    const width = props.width + 'px';
    const text = props.text;
    const logo = props.logo;

    return (
        <button
            style={{
                height: 'inherit',
                backgroundColor: '#FFFF',
                border: 'none',
                borderRadius: '5px',
                //height: height,
                width: width,
                padding: '0',
                boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 2px 0px',
                textAlign: 'left',
                verticalAlign: 'center'
            }}
            onClick={() => props.onClick()}>
            <img
                src={logo}
                height={'18px'}
                //width={'18px'}
                style={{marginLeft: '8px', marginTop: '8px', marginBottom: '8px'}}
                />
            <span style={{
                marginLeft: '16px',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: '8px',
                fontSize: 'medium',
                verticalAlign: 'middle'
            }}>{text}</span>
        </button>
    );
}