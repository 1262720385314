import React, { Component, useEffect, useState, useContext } from 'react';
import { Link, useLocation, Redirect } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const AppleResponse = (props) => {
    //alert('asdf');
    //const querystr = new URLSearchParams(useLocation().search);

    const { user, checkLogin } = useContext(UserContext);

    const [signingIn, setSigningIn] = useState(false);

    useEffect(() => {
        //alert(querystr);
        appleLogin();

    }, []);

    const appleLogin = async () => {
        setSigningIn(true);
        const postOptions = {
            method: 'GET',
        };

        //const response = await fetch('api/authenticate/googleresponse', postOptions);
        //const response = await fetch('identity/signin-apple', postOptions);

        //if (response.status == 200) {
            const response2 = await fetch('api/authenticate/externalresponse', postOptions);

        //}
        //else {
            //alert('Apple Login Failed');
        //}

        checkLogin();

        setSigningIn(false);
        props.history.push('/');
    }

    return (
        <div>
            <div className="text-center mt-3 foreground-primary">
                <i className="fas fa-poo fa-3x"></i>
            </div>
            {signingIn &&
                <div className="text-center mt-3 text-dark">
                    Checking Login
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">...</span>
                    </div>
                </div>
            }
        </div>
    );
}