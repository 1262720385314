import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const Logout = (props) => {
    const { user, updateUser } = useContext(UserContext);

    useEffect(() => {
        LogoutUser();
    }, []);

    const LogoutUser = async () => {
        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {}
        };

        //localStorage.setItem('username', '');
        //localStorage.setItem('isLoggedIn', false);

        const response = await fetch('api/authenticate/logout', postOptions);

        if (response.status == 200) {
            updateUser(null);
        }
        else {
            alert('Logout Failed');
        }

        props.history.push('/');
    }
    return ('Logging Out...');
}