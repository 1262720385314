export default class Utils {
    static IsLoggedInLight = () => {
        var _isLoggedIn = localStorage.getItem('isLoggedIn');
        if (_isLoggedIn === null) {
            return false;
        }
        else {
            if (_isLoggedIn === 'true') {
                return true;
            }
        }

        return false;
    }

    static IsLoggedIn = async () => {
        const response = await fetch('api/authenticate/isloggedin');
        const _isLoggedInModel = await response?.json();

        let _isLoggedIn = false;
        let _username = null;
        let _userid = '';
        let _roles = [];

        if (_isLoggedInModel != null) {

            //localStorage.setItem('username', _isLoggedInModel.username);
            //localStorage.setItem('isLoggedIn', _isLoggedInModel.isLoggedIn);
            _isLoggedIn = _isLoggedInModel.isLoggedIn;
            _username = _isLoggedInModel.username;
            _userid = _isLoggedInModel.userId;
            _roles = _isLoggedInModel.roles;
        }

        return {_isLoggedIn, _username, _userid, _roles};
    }

    static SendDeviceInfo = async (info) => {
        let fcmToken, deviceOS, deviceId;
        if (info == null) {
            fcmToken = localStorage.getItem('fcmToken');
            deviceOS = localStorage.getItem('OS');
            deviceId = localStorage.getItem('deviceid');
        }
        else {
            fcmToken = info.fcmToken;
            deviceOS = info.deviceOS;
            deviceId = info.deviceId;
        }

        const deviceInfo = {
            fcmtoken: fcmToken,
            deviceos: deviceOS,
            deviceid: deviceId
        };

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(deviceInfo)
        };

        const response = await fetch('api/device', postOptions);

        if (response.status == 200)
            return true;
        else
            return false;
    }
}