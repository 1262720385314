import React, { useEffect, useRef, createContext, useContext } from 'react';
import fartFile from '../sounds/medium-realpoot110.mp3';
import yayFile from '../sounds/428156__higgs01__yay.wav';

const SoundContext = createContext(true);

export const SoundProvider = ({ children }) => {
    const fartAudioElement = useRef(new Audio(fartFile));
    const yayAudioElement = useRef(new Audio(yayFile));

    return (
        <SoundContext.Provider value={{ fart: fartAudioElement.current, yay: yayAudioElement.current }}>
            {children}
        </SoundContext.Provider>
    );
}

export const useSound = () => {
    const store = useContext(SoundContext);
    return store;
};