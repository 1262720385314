import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserProvider';
import Utils from '../../code/Utils';
import { AppleSigninButton } from './AppleSigninButton';
import { GoogleSigninButton } from './GoogleSigninButton';

export const Login = (props) => {
    const [formValues, setValues] = useState({
        username: '',
        //email: '',
        password: '',
        rememberme: true
    });

    useEffect(() => {

    }, []);

    const [error, setError] = useState('');

    const { user, checkLogin } = useContext(UserContext);

    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        
        setValues({ ...formValues, [e.target.name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        LoginUser();
    }

    const ContinueAsGuest = async () => {
        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            //body: JSON.stringify(loginModel)
        };

        const response = await fetch('api/authenticate/loginguest', postOptions);

        if (response.status == 200) {
            checkLogin();
        }
        else {
            setError('Guest Login failed. Try again');
        }
    }

    const GoogleLogin = async () => {

        const postOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
            }
        };

        const response = await fetch('api/authenticate/login-google', postOptions);

        if (response.status == 200) {
            var url = await response.text();
            //window.open(url, '_blank');
            window.location.assign(url);
        }
        else {
            setError('Google Signin Failed. Try again');
        }
    }

    const AppleLogin = async () => {

        const postOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
            }
        };

        const response = await fetch('api/authenticate/login-apple', postOptions);

        if (response.status == 200) {

            var url = await response.text();
            window.location.assign(url);
        }
        else {
            setError('Apple Signin Failed. Try again');
        }
    }

    const LoginUser = async () => {
        var loginModel = formValues;

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginModel)
        };

        const response = await fetch('api/authenticate/login', postOptions);
       
        if (response.status == 200) {
            checkLogin();
        }
        else {
            setError('Username/Password combination failed. Try again');
        }
        
    }

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    <h4>Login</h4>
                </div>
                <div className="card-body">
                    <div className="p-3" style={{ textAlign: 'center' }}>
                        <GoogleSigninButton width='200' height='50' onClick={GoogleLogin} />
                    </div>                   
                    <div className="p-3" style={{textAlign: 'center'}}>
                        <AppleSigninButton width='200' height='50' onClick={AppleLogin} />
                    </div>
                    <div className="row p-3">
                        <div className="col"><hr /></div>
                        <div className="col-auto">Or</div>
                        <div className="col"><hr /></div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Enter User Name"
                                onChange={handleInputChange}>
                            </input>
                        </div>
                        <div className="form-group mt-1">
                            <label>Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Enter Password"
                                onChange={handleInputChange}>
                            </input>
                        </div>
                        <div className="form-check mt-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name="rememberme"
                                checked={formValues.rememberme}
                                onChange={handleInputChange}>
                            </input>
                            <label className="form-check-label">Remember Me</label>
                        </div>
                        <div className="form-group">
                            <Link to={{ pathname: '/authorization/ForgotPassword' }}>Forgot Password?</Link>
                        </div>
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <div className="mt-1">
                                <span style={{ color: "red" }}>{error}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="mt-1 text-center">Or</div>
            <div className="card bg-light mt-1">
                <div className="p-3 text-center">
                    <Link className="btn btn-navColor" to={{ pathname: "/authorization/Register" }}>Register</Link>
                </div>
            </div>
            <div className="mt-1 text-center">Or</div>
            <div className="card bg-light mt-1">
                <div className="p-3 text-center">
                    <button className="btn btn-secondary btn-sm" onClick={ContinueAsGuest}>Continue as Guest</button>
                </div>
            </div>
        </div>
    );
}