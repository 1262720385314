import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const Confirmation = (props) => {
    const okClick = () => {
        props.okClick();
    }

    return (
        <Modal zIndex='30001' isOpen={props.isOpen} toggle={() => props.toggle()}>
            <ModalBody>
                <div className='position-relative' style={{ height: '100%' }}>
                    <div className="text-center mt-3 text-success">
                        <i className="fas fa-poo fa-3x"></i>
                    </div>
                    <div className="text-center mt-3">
                        <h4>{props.message}</h4>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="container">
                    <div className="d-flex justify-content-around">
                        <button className="btn btn-primary" onClick={okClick}>Ok</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}