import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from 'react-leaflet'
import { Link } from 'react-router-dom';

export const DropPinMapComponent = (props) => {
    return (
        <div className="position-relative" style={{ height: '100%' }}>
            <MapContainer id='dropPinMap' center={[0, 0]} dragging={false} touchZoom={false} zoomControl={false} zoom={18} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DropPinMapController crapper={props.crapper} onLocationUpdated={props.onLocationUpdated} />
            </MapContainer>
        </div>
    );
}

const DropPinMapController = (props) => {
    const initCoords = { lat: 0, lng: 0 };

    const [pinCoords, setPinCoords] = useState(initCoords);

    //since mapcontainer props are immutable, need to edit them with this child component
    const map = useMap();

    const crapper = props.crapper;
    const isEditing = crapper == null ? false : true;

    useEffect(() => {
        //if editing position
        if (crapper != null) {
            var coords = { lat: crapper.gpslat, lng: crapper.gpslong };
            map.setView(coords, 20);
            setPinCoords(coords);
        }
    }, []);

    return (
        <div>
            <Marker
                position={[pinCoords.lat, pinCoords.lng]}
                draggable={false}
                >            
            </Marker>
        </div>
    );
}