import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { Rating } from './Rating';
import { UserContext } from './authorization/UserProvider';
import { ReportCrapper } from './ReportCrapper';
import { Confirmation } from './Confirmation';
import { useSound } from './SoundProvider';
import { DropPinMapComponent } from './DropPinMapComponent';
import { PoochievementNotificationContext } from './PoochievementNotification';

export const AddCrapperForm = (props) => {

    //if editing initially fill form values with this crapper
    const crapper = props.crapperAndRating?.crapper;

    const { user } = useContext(UserContext);
    const pooChievementsObject = useContext(PoochievementNotificationContext);
    const initialValues = {
        BathroomId: crapper?.bathroomId ?? 0,
        LocationName: crapper?.locationName ?? '',
        Multistall: crapper?.multistall ?? false,
        SeparateMens: crapper?.separateMens ?? false,
        AllGender: crapper?.allGender ?? false,
        HandicapAccessible: crapper?.handicapAccessible ?? false,
        BabyChangeStation: crapper?.babyChangeStation ?? false,
        Shower: crapper?.shower ?? false,
        Fee: crapper?.fee ?? false,
        GPSLat: crapper?.gpslat ?? 0,
        GPSLong: crapper?.gpslong ?? 0,
        BathroomTypeId: crapper?.bathroomTypeId ?? 1,
        Rating: -1,
        Comment: ''
    };

    const [formValues, setValues] = useState(initialValues);

    const [bathroomTypes, setTypes] = useState({});
    const [addressCollapsed, setAddressCollapsed] = useState(true);
    const [GPSCollapsed, setGPSCollapsed] = useState(true);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [reportCrapperVisible, setReportCrapperVisible] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editingCrapperLocation, setEditingCrapperLocation] = useState(false);

    const toggleConfirmation = () => {
        let isOpenBeforeToggle = confirmationOpen;
        setConfirmationOpen(!confirmationOpen);

        //if the confirmation message opened, it means we are done successfully editing/adding the crapper.
        //this means the user either clicked ok, or clicked away from the confirmation message to close the popup
        //if (isOpenBeforeToggle)
            //props.history.push('/');
    }

    const confirmationOkClicked = () => {
        toggleConfirmation();
        props.onRatingSubmit();
    }

    const audioElement = useSound();
    const fart = audioElement?.fart;

    useEffect(() => {
        if (props.isOpen) {
            if (user == null || user.isLoggedIn == false) {
                props.toggleClosed();
                return;
            }

            if (!editingCrapperLocation) {
                setValues(initialValues);
                getBathroomTypesList();
                DeleteButtonVisibility();
            }
            else {
                setValues({ ...formValues, GPSLat: crapper?.gpslat ?? 0, GPSLong: crapper?.gpslong ?? 0 });
                setEditingCrapperLocation(false);
            }

            setReportCrapperVisible(false);
        }
    }, [props.isOpen]);

    const DeleteButtonVisibility = async () => {
        //if this is the user who created the crapper AND there aren't any reviews from other users yet, then allow delete
        let isSameUser = false;
        let hasRatingsFromOtherUsers = false;

        if (user?.username != null) {
            if (user.userid == crapper?.createdBy) {
                isSameUser = true;
            }
        }

        if (formValues.BathroomId != 0) {
            const ratingsResponse = await fetch('api/rating/' + formValues.BathroomId);

            const data = await ratingsResponse?.json();

            if (data != null)
                hasRatingsFromOtherUsers = data.some(r => r.username != user?.username);
        }

        if (isSameUser && !hasRatingsFromOtherUsers)
            setShowDeleteButton(true);
    }

    const handleInputChange = (e) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if (e.target.name == 'BathroomTypeId') {
            let intVal = parseInt(value, 10);
            value = isNaN(intVal) ? 0 : intVal;
        }

        setValues({ ...formValues, [e.target.name]: value });
    }

    const onStarClick = (starNum) => {
        setValues({ ...formValues, Rating: starNum });
    }

    const onReportSubmitted = () => {
        setReportCrapperVisible(false);
        props.toggleClosed();
    }

    const onDeleteCrapper = async () => {
        var bathId = crapper?.bathroomId;
        if (bathId == null)
            return;

        const r = window.confirm("Are you sure you want to delete this crapper?!");
        if (r == false)
            return;

        const postOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch('api/bathroom/' + bathId, postOptions);

        if (response.status == 200) {
            props.toggleClosed();
            props.onRatingSubmit();
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = await createCrapper();

        if (result) {
            let confirmText = formValues.BathroomId == 0 ? 'Crapper Successfully Created!' : 'Crapper Successfully Edited!';
            setConfirmationMessage(confirmText);
            toggleConfirmation();
            props.toggleClosed();
        }
        
    }

    const getBathroomTypesList = async () => {

        const response = await fetch('api/bathroom/types');

        const data = await response?.json();

        setTypes(data);
    }

    const editLocationClicked = () => {
        setEditingCrapperLocation(true);
        var coords = { lat: crapper.gpslat, lng: crapper.gpslong };
        props.onEditLocationClick(coords);
        props.toggleClosed(true);
    }

    const createCrapper = async () => {
        setSaving(true);

        var bath = {
            BathroomId: formValues.BathroomId,
            LocationName: formValues.LocationName,
            Multistall: formValues.Multistall,
            SeparateMens: formValues.SeparateMens,
            AllGender: formValues.AllGender,
            HandicapAccessible: formValues.HandicapAccessible,
            BabyChangeStation: formValues.BabyChangeStation,
            Shower: formValues.Shower,
            Fee: formValues.Fee,
            Address: formValues.Address,
            City: formValues.City,
            State: formValues.State,
            Zipcode: formValues.Zipcode,
            GPSLat: formValues.GPSLat,
            GPSLong: formValues.GPSLong,
            BathroomTypeId: formValues.BathroomTypeId
        };

        var rating = {
            Rating: formValues.Rating,
            Comment: formValues.Comment
        };

        var viewModel = { bathroom: bath, rating: rating };

        var method = bath.BathroomId == 0 ? 'POST' : 'PUT';

        const postOptions = {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(viewModel)
        };

        const response = await fetch('api/bathroom', postOptions);

        if (response.status == 200) {
            let didUserGetPoochievements = await pooChievementsObject?.CheckForNewPoochievements();

            //only play the fart if the user didn't get any poochievements from this rating
            if (didUserGetPoochievements == null || didUserGetPoochievements == false) {
                fart.play(); //play fart
            }

            setSaving(false);
            return true;        
        }
        else {
            response.text().then(text => alert(text));
            setSaving(false);
            return false;
        }
    }

    const toggleOpenClosed = () => {
        if (props.isOpen)
            props.toggleClosed();
        else
            props.toggleOpen();
    }

    return (
        <div>
            <Modal zIndex="20003" isOpen={props.isOpen} toggle={toggleOpenClosed}>
                <ModalHeader className="bg-light" toggle={toggleOpenClosed}>
                    {formValues?.BathroomId != 0 ? 'Edit Crapper' : 'Create Crapper'}
                </ModalHeader>
                <ModalBody>
                <div className="card bg-light mt-3">
                    <div className="card-header">
                        <b>Location</b>
                    </div>
                    <div className="card-body">
                        <div style={{ height: '200px' }}>
                            <DropPinMapComponent crapper={crapper} />
                        </div>
                        <button className="btn btn-secondary text-white mt-2" onClick={editLocationClicked}>
                            Edit     <i className="fas fa-edit"></i>
                        </button>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="card bg-light mt-3">
                        <div className="card-header">
                            <b>Basic Info</b>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>Location Name:</label>               
                                <input
                                    type="text"
                                    className="form-control"
                                    name="LocationName"
                                    placeholder="Enter Text"
                                    value={formValues.LocationName}
                                    onChange={handleInputChange}>
                                </input>                   
                            </div>
                            <div className="form-group">
                                <label>Bathroom Type</label>
                                <select
                                    className="form-control"
                                    name="BathroomTypeId"
                                    value={formValues.BathroomTypeId}
                                    onChange={handleInputChange}>
                                    {Object.values(bathroomTypes).map((btype) =>
                                        <option key={btype.bathroomTypeId} value={btype.bathroomTypeId}>{btype.typeName}</option>
                                    )}                             
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="card bg-light mt-3">
                        <div className="card-header">
                            <b>Amenities</b>
                        </div>
                        <div className="card-body">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Multistall"
                                    checked={formValues.Multistall}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Multi-stall</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="SeparateMens"
                                    checked={formValues.SeparateMens}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Separate Womens & Mens</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="AllGender"
                                    checked={formValues.AllGender}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">All Gender</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="HandicapAccessible"
                                    checked={formValues.HandicapAccessible}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Handicap Accessible</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="BabyChangeStation"
                                    checked={formValues.BabyChangeStation}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Baby Changing Station</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Shower"
                                    checked={formValues.Shower}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Shower</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Fee"
                                    checked={formValues.Fee}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Fee</label>
                            </div>
                        </div>
                    </div>
                    {formValues.BathroomId == 0 &&
                        <div className="card bg-light mt-3">
                            <div className="card-header">
                                <b>Rating</b>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label>How to you rate your experience?</label>
                                    <Rating
                                        rating={formValues.Rating}
                                        canEdit={true}
                                        onStarClick={onStarClick} />
                                </div>
                                <div className="form-group mt-2">
                                    <label>Talk about your experience:</label>
                                    <textarea
                                        wrap="soft"
                                        className="form-control"
                                        name="Comment"
                                        placeholder="Enter comments"
                                        value={formValues.Comment}
                                        onChange={handleInputChange}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="card bg-light mt-3">
                        <div className="d-flex justify-content-around m-3">
                            {saving ?
                                <button disabled className="btn btn-lg btn-primary">Saving...</button>
                                :
                                <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                            }
                        
                                <button type="button" className="btn btn-lg btn-secondary" onClick={() => props.toggleClosed()}>Cancel</button>
                        </div>
                    </div>
                    {showDeleteButton &&
                        <div className="card bg-light mt-3">
                            <div className="p-3 text-center">
                                <Button size="sm" color="danger" onClick={onDeleteCrapper}>Delete Crapper</Button>
                            </div>
                        </div>
                    }
                    {!showDeleteButton && formValues.BathroomId != 0 &&
                        <div className="card bg-light mt-3">
                            <div className="p-3 text-center">
                            <Button size="sm" color="danger" onClick={() => setReportCrapperVisible(!reportCrapperVisible)}>
                                Report <i className="fas fa-flag"></i>
                            </Button>
                            </div>
                        </div>
                        }

                </form>
                <Modal zIndex='20100' isOpen={reportCrapperVisible} toggle={() => setReportCrapperVisible(!reportCrapperVisible)}>
                    <ModalHeader>Report</ModalHeader>
                    <ModalBody>
                        <ReportCrapper crapperId={formValues.BathroomId} onReportSubmit={onReportSubmitted} onCancel={() => setReportCrapperVisible(!reportCrapperVisible)} />
                    </ModalBody>
                </Modal>             
                </ModalBody>
            </Modal>
            <Confirmation isOpen={confirmationOpen} toggle={confirmationOkClicked} okClick={confirmationOkClicked} message={confirmationMessage} />
        </div>
    );
}