import React, { Component, useEffect, useState, useContext } from 'react';
import { NavMenu } from './NavMenu';

export const Layout = (props) => {
    const displayName = Layout.name;
    const children = props.children;

    return (
        <div className="app-background">
            <div className="app-navContainer">
                <NavMenu />
            </div>
            <div className="app-content">
                {children}
            </div>
        </div>
    );
}
