import React, { Component } from 'react';
import { ExternalSigninButton } from './ExternalSigninButton';

export const GoogleSigninButton = (props) => {
    const height = props.height;
    const width = props.width;
    const buttonText = 'Sign in with Google';
    const logo = 'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
    return (
        <ExternalSigninButton
            width={props.width}
            height={props.height}
            logo={logo}
            text={buttonText}
            onClick={props.onClick}
        />
    );
}