import React, { Component, useRef, useState, useEffect } from 'react';

export const GooglePlaceSearchBox = (props) => {

    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        //componentRestrictions: { country: "us" },
        fields: ["geometry", "name"],
    };

    const addGooglePlacesScript = () => {
        const existingScript = document.getElementById('googlePlaces');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBngU2phEcG_QO8iCJOaHSMnQ24imOBhsM&libraries=places';
            script.id = 'googlePlaces';
            script.async = true;
            script.defer = true;

            document.body.appendChild(script);

            script.onload = () => {
                setupAutocomplete();
            };
        }
    };

    const setupAutocomplete = () => {

        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );

        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            const location = place?.geometry?.location;
            if (location != null) {
                let lat = location.lat();
                let lng = location.lng();
                let coords = { lat: lat, lng: lng };
                props.placeSelected(coords)
            }
        });
    }

    useEffect(() => {
        addGooglePlacesScript();
    }, []);

    const onInputDivClick = () => {
        if (inputRef?.current != null) {
            inputRef.current.focus();
        }
    }

    return (
        <div className="p-2 bg-transparent" onClick={onInputDivClick}>
            <input
                ref={inputRef}
                type="text"
                className="form-control"
                name="googleSearchBox"
                placeholder="Search"                  
                >
            </input>
        </div>
    );
}