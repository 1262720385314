import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const ForgotPassword = (props) => {
    const [username, setUsername] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [completed, setCompleted] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;

        setUsername(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setInProgress(true);
        CreateResetToken();

    }

    const CreateResetToken = async () => {
        var forgotPasswordModel = { Username: username };

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(forgotPasswordModel)
        };

        const response = await fetch('api/authenticate/forgotpassword', postOptions);

        setInProgress(false);
        if (response.status == 200) {
            //props.history.push('/');
            setCompleted(true);
        }
        else {
            response.text().then(text => alert(text));
            //const asdf = await response.json()
            //alert(asdf);
        }

    }

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    Forgot Password
                </div>
                <div className="card-body">
                    {completed &&
                        'Submitted! Check your email for a link to reset your login.'
                    }
                    {!completed &&
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Enter Username"
                                onChange={handleInputChange}>
                            </input>
                        </div>
                        <div className="container mt-4">
                            <div className="d-flex justify-content-around">
                                <button type="submit" disabled={inProgress} className="btn btn-primary">Submit</button>
                                <Link className="btn btn-danger" to={{ pathname: "/" }}>Cancel</Link>
                            </div>
                        </div>
                    </form>
                    }
                </div>
            </div>
        </div>
    );
}