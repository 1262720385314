import React, { useEffect, createContext, useContext, useState } from 'react';

export const ServiceWorkerContext = createContext();

export const ServiceWorkerProvider = ({ children }) => {
    const [version, setVersion] = useState(null);

    const messageChannel = new MessageChannel();

    useEffect(() => {
        navigator.serviceWorker.controller?.postMessage({
            type: 'INIT_PORT',
        }, [messageChannel.port2]);

        messageChannel.port1.onmessage = (event) => {
            setVersion(event?.data?.payload);
        };

        navigator.serviceWorker.controller?.postMessage({
            type: 'GET_VERSION',
        });
    }, []);

    return (
        <ServiceWorkerContext.Provider value={version}>
            {children}
        </ServiceWorkerContext.Provider>
    );
}