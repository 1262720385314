import React, { useEffect, useState, createContext } from 'react';
import Utils from '../../code/Utils.js';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const updateUser = (userDeets) => {
        setUser(userDeets);
    }

    const checkLogin = async () => {
        setIsLoggingIn(true); //checking login

        let response = await Utils.IsLoggedIn();

        updateUser({ username: response._username, isLoggedIn: response._isLoggedIn, userid: response._userid, roles: response._roles });

        setIsLoggingIn(false);
    }

    useEffect(() => {
        if (user == null) {
            //alert('in user provider');
            checkLogin();
            //Utils.SendDeviceInfo();
        }
    }, []);

    return (
        <UserContext.Provider value={{ user, updateUser, checkLogin, isLoggingIn }}>
            {children}
        </UserContext.Provider>
    );
}