import React, { useState, createContext, useEffect, useContext } from 'react';
import { Notification } from './Notification';
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from './authorization/UserProvider';
import Utils from '../code/Utils.js';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState(null);
    const { user } = useContext(UserContext);

    const querystr = useLocation().search;
    const history = useHistory();

    useEffect(() => {
        //add the event handler that listens to the ios app push event
        SubscribeToPush();

        return () => {
            UnSubscribeToPush();
        }
    }, []);

    useEffect(() => {
        if (user != null && user.isLoggedIn) {
            TasksAfterLoggedIn();
        }
    }, [user]);

    const TasksAfterLoggedIn = async () => {
        //check for new notifications this user should see
        await CheckForNotifications();

        //android app sets the query string with notification stuff. check it
        if (querystr !== "") {

            const params = new URLSearchParams(querystr);

            //if applicable send firebase info if we have received an fcm token
            let fcmToken = params.get("fcmtoken");
            let deviceOS = params.get("deviceos");
            let deviceId = params.get("deviceid");
            if (fcmToken != null) {
                await Utils.SendDeviceInfo({ fcmToken, deviceOS, deviceId });
            }

            //check if a message has come in
            let messageId = params.get("messageid");
            if (messageId != null) {
                let idNum = parseInt(messageId);
                if (idNum != NaN) {
                    await handlePush(idNum);
                }
            }

            //remove the querystring
            history.replace({ search: '' });
        }
    }

    const SubscribeToPush = () => {
        //ios
        document.addEventListener('push-notification', handleIosPush, false);

        //android
        //document.addEventListener('push-notification', handleIosPush);
    }

    const UnSubscribeToPush = () => {
        //ios
        document.removeEventListener('push-notification', handleIosPush);

        //android
        //document.addEventListener('push-notification', handleIosPush);
    } 

    const handleIosPush = async (data) => {
        if (data?.detail?.Id != null) {
            let idNum = parseInt(data.detail.Id);
            if (idNum != NaN) {
                await handlePush(idNum);
            }
        }
    }

    const handlePush = async (Id) => {
        let notificationFromDb = await getNotification(Id);
        if (notificationFromDb != null) {
            await OpenNotificationPopup(notificationFromDb);
        }
    }

    const getNotification = async (Id) => {
        const response = await fetch('api/notification/' + Id);

        if (response.status == 200) {
            const data = await response?.json();
            return data;
        } else {
            return null;
        }
    }

    const okClick = () => {
        CloseNotificationPopup();
    }

    const OpenNotificationPopup = async (notification) => {
        //set the state for the notification popup, then open
        setNotification(notification);
        setPopupOpen(true);

        //now update the server that this notification has now been seen
        if (notification != null && notification.notificationId > 0 && notification.seen == false) {
            //set as seen for the UI in order to trigger change of color
            let notificationsCopy = notifications;
            let notificationToMarkSeen = notificationsCopy.find(n => n.notificationId == notification.notificationId);
            if (notificationToMarkSeen?.seen != null) {
                notificationToMarkSeen.seen = true;
                setNotifications(notificationsCopy);
            };

            //set as seen in the db so that it wont show up next time
            await SetNotificationAsSeen(notification.notificationId);
        }
    }

    const SetNotificationAsSeen = async (id) => {

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: id
        };

        const response = await fetch('api/notification/userseen', postOptions);
    }

    const CloseNotificationPopup = async () => {
        setPopupOpen(false);
        setNotification(null);
        await CheckForNotifications();
    }

    const CheckForNotifications = async () => {
        
        const response = await fetch('api/notification/latest');

        if (response.status == 200) {
            const data = await response?.json();

            if (data != null && data.length > 0) {
                let notificationsList = [];
                for (let i = 0; i < data.length; i++) {
                    //let thisNotification = { notification: data[i], seen: false };
                    notificationsList.push(data[i]);
                }

                setNotifications(notificationsList);
                return true;
            }
        }

        setNotifications([]);
        return false;
        
    }

    return (
        <NotificationContext.Provider value={{ CheckForNotifications, OpenNotificationPopup, CloseNotificationPopup, notifications }}>
            <Notification isOpen={popupOpen} okClick={okClick} notification={notification} />
            {children}
        </NotificationContext.Provider>
    );
}