import React, { Component, useRef, useState, useEffect, useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet'
import { Link } from 'react-router-dom';
import bluedot from '../symbols/blue dot 12x12.png'; // relative path to image 
import { Rating } from './Rating';
import { SetRating } from './SetRating';
import { Amenities } from './Amenities.js';
import { DivIcon, Icon } from 'leaflet';
import { post } from 'jquery';
import { Reviews } from './Reviews';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavLink } from 'reactstrap';
import { UserContext } from './authorization/UserProvider';
import { Confirmation } from './Confirmation';

export const CrapperMarker = (props) => {
    var crapper = props.Crapper;
    const position = [crapper.gpslat, crapper.gpslong];

    const bathType = crapper?.bathroomType?.typeName;

    let iconHtmlToUse;
    if (crapper?.rating == -1) {
        iconHtmlToUse = renderToString(<PooMarkerNoRatings />);
    }
    else {
        iconHtmlToUse = renderToString(<PooMarkerIcon />);
    }
  
    const myIcon = new DivIcon({
        html: iconHtmlToUse,
        className: '', //leave blank to overwrite the default class (caused blank white squares)
        iconSize: [36, 48],
        iconAnchor: [25, 48],
        popupAnchor: [6,-48]
    });

    const { user } = useContext(UserContext);
    const isLoggedIn = user != null && user.roles != null && !user.roles.includes('Guest');
    const registerLink = <Link to={{ pathname: "/authorization/Register" }}>Register</Link>
    const loginLink = <Link to={{ pathname: "/authorization/Logout" }}>Login</Link>

    const [commentsVisible, setCommentsVisible] = useState(false);
    const [submitRatingVisible, setSubmitRatingVisible] = useState(false);
    //const [ratingToEdit, setRatingToEdit] = useState(null);
    //const [isLoggedIn, setIsLoggedIn] = useState(false);
    //const popupRef = useRef(null);

    const onShowCommentsClick = () => {
        setCommentsVisible(!commentsVisible);
    }

    const onShowSubmitRatingClick = () => {
        setSubmitRatingVisible(!submitRatingVisible);
    }

    const onRatingSubmit = () => {
        //setSubmitRatingVisible(!submitRatingVisible);
        props.onRatingSubmit();
    }

    const onEditCrapperClicked = () => {
        props.onEditCrapperClicked(crapper);
    }

    useEffect(() => {
        //if (user != null)
        //    setIsLoggedIn(user.isLoggedIn);
        //else
        //    setIsLoggedIn(false);
    }, []);

    const popupWidth = commentsVisible ? '100%' : '';

    return (
        <Marker         
            position={position}
            icon={myIcon}
        >
            <Popup>
                <div>                  
                    <span style={{ fontSize: 'medium', fontWeight: 'bold', display: 'block' }}>{crapper.locationName}</span>                       
                    <span style={{ fontSize: 'x-small', display: 'block' }}>{bathType}</span>
                    <Rating rating={crapper?.rating} />
                    <div style={{height: '5px'}}></div>
                    <Amenities crapper={crapper} />
                    {isLoggedIn ?
                        <div>
                            <div className="d-flex justify-content-between">
                                <Button color="primary" onClick={onShowSubmitRatingClick}>
                                    Rate! <i className="far fa-star"></i>
                                </Button>
                                <button className="btn btn-secondary text-white" onClick={onEditCrapperClicked}>
                                    Edit   <EditCrapperIcon />
                                </button>
                            </div>                          
                            <div className="d-flex justify-content-center mt-3">
                                <Button color="link" size="sm" onClick={onShowCommentsClick}>See Reviews
                                    <i className="fas fa-chevron-down"></i>
                                </Button>
                            </div>                     
                        </div>
                        :
                        <div className="mt-2">{registerLink} or {loginLink} to see and add reviews!</div>
                    }
                </div>
            </Popup>
            <Modal zIndex="20002" isOpen={commentsVisible} toggle={onShowCommentsClick}>
                <ModalHeader className="bg-light">{crapper.locationName}</ModalHeader>
                <ModalBody>
                    <Reviews crapper={crapper}/>
                </ModalBody>
                <ModalFooter>
                    <div className="container">
                        <div className="d-flex justify-content-around">
                            <Button color="secondary" onClick={onShowCommentsClick}>Close</Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            <SetRating isOpen={submitRatingVisible} toggle={onShowSubmitRatingClick} crapper={crapper} onRatingSubmit={onRatingSubmit} onCancel={onShowSubmitRatingClick} />
        </Marker>
    );
}

export const UserMarker = (props) => {
    const myIcon = new Icon({
        iconUrl: bluedot
    });

    return (
        <Marker        
            position={props.position}
            icon={myIcon}
        />
    );
}

export const DropPinMarker = (props) => {
    return (
        <Marker
            position={props.position}
            draggable={true}
            eventHandlers={{
                dragend: props.onPinDragEnd
            }}
            ref={props.ref}
        />
    );
}

export const NewCrapperMarker = (props) => {
    return (
        <Marker
            position={props.position}
            draggable={false}
        >     
        </Marker>
    );
}

const ToiletMarkerIcon = () => {
    return (
        <span className="fa-stack fa-2x">
                <i className="fas fa-map-marker fa-stack-2x markerGradColor"></i>
                <i className="fas fa-toilet fa-stack-1x fa-inverse"></i>
        </span>
    );
}

const ToiletMarkerNoRatings = () => {
    return (
        <span className="fa-stack fa-2x">
            <i className="fas fa-map-marker fa-stack-2x markerNoRatings"></i>
            <i className="fas fa-toilet fa-stack-1x fa-inverse"></i>
        </span>
    );
}

const PooMarkerIcon = () => {
    return (
        <span className="fa-stack fa-2x" style={{ lineHeight: 'inherit' }}>
            <i className="fas fa-map-marker fa-stack-2x markerGradColor"></i>
            <i className="fas fa-poo fa-stack-1x fa-inverse"></i>
        </span>
    );
}

const PooMarkerNoRatings = () => {
    return (
        <span className="fa-stack fa-2x" style={{ lineHeight: 'inherit' }}>
            <i className="fas fa-map-marker fa-stack-2x markerNoRatings"></i>
            <i className="fas fa-poo fa-stack-1x fa-inverse"></i>
        </span>
    );
}

const EditCrapperIcon = () => {
    return (
        <i className="fas fa-edit"></i>
    );
}