import React, { Component, useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const ResetPassword = (props) => {
    const querystr = new URLSearchParams(useLocation().search);

    const username = querystr.get("username");
    const token = querystr.get("token");
    const [newPassword, setPassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [completed, setCompleted] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;

        setPassword(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setInProgress(true);
        SetNewPassword();

    }

    const SetNewPassword = async () => {
        var resetPasswordModel = { Username: username, NewPassword: newPassword, Token: token };

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(resetPasswordModel)
        };

        const response = await fetch('api/authenticate/resetpassword', postOptions);

        setInProgress(false);
        if (response.status == 200) {
            //props.history.push('/');
            setCompleted(true);
        }
        else {
            response.text().then(text => alert(text));
            //const asdf = await response.json()
            //alert(asdf);
        }

    }

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    Reset Password
                </div>
                <div className="card-body">
                    {completed &&
                        <div>
                            <span>Success!</span><br/>
                            <Link to={{ pathname: '/' }}>Go to Login</Link>
                        </div>
                    }
                    {!completed &&
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>New Password:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="Enter Password"
                                    onChange={handleInputChange}>
                                </input>
                            </div>
                            <div className="container mt-4">
                                <div className="d-flex justify-content-around">
                                    <button type="submit" disabled={inProgress} className="btn btn-primary">Submit</button>
                                    <Link className="btn btn-danger" to={{ pathname: "/" }}>Cancel</Link>
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    );
}