import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, useMapEvent } from 'react-leaflet';
import { CrapperMarker, NewCrapperMarker, UserMarker } from './Marker.js';
import { GooglePlaceSearchBox } from './GooglePlaceSearchBox';
import { Link } from 'react-router-dom';
import { map } from 'jquery';
import { UserContext } from './authorization/UserProvider';
import { useOnlineStatus } from './OnlineStatusProvider.js';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { FilterButton } from './FilterButton';
import { AddCrapperForm } from './AddCrapperForm.js';

export const Map = (props) => {

    const isOnline = useOnlineStatus();

    if (isOnline) {
        return (
            <div id="divMap" className='position-relative' style={{ height: '100%' }}>
                <MapContainer id="map" center={[33.7371583, -84.36820999999999]} touchZoom={true} zoomControl={false} zoom={16} maxZoom={19} scrollWheelZoom={false}>
                    <ZoomControl style={{zIndex: '10002'}} position='topleft' />
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom='19'
                        maxNativeZoom='19'
                    />
                    <MapController />
                </MapContainer>
            </div >
        );
    }
    else {
        return (
            <div id="divOffline" className='position-relative' style={{ height: '100%' }}>
                <div style={{textAlign: 'center', marginTop: '20px'}}>
                    <i className="fas fa-poo fa-3x"></i>
                </div>           
                <div className="text-center mt-3">
                    Oops, looks like you are offline
                </div>
            </div>
        );
    }
}

export const MapController = (props) => {
    //since mapcontainer props are immutable, need to edit them with this child component
    const map = useMap();
    
    const isOnline = useOnlineStatus();
    const { user } = useContext(UserContext);
    
    const isLoggedIn = user != null && user.isLoggedIn && user.roles != null && !user.roles.includes('Guest');

    const registerLink = <Link to={{ pathname: "/authorization/Register" }}>Register</Link>
    const loginLink = <Link to={{ pathname: "/authorization/Logout" }}>Login</Link>

    const initCoords = { lat: 0, lng: 0 };
    const [crappers, setCrappers] = useState({});
    const [showSearchButton, setShowSearchButton] = useState(false);
    const [userCoords, setUserCoords] = useState(initCoords);
    const [clusterKey, setClusterKey] = useState(0);
    const [updatingCrappers, setUpdatingCrappers] = useState(false);
    const [showZoomInMessage, setShowZoomInMessage] = useState(false);
    const [showNewCrapperMarker, setShowNewCrapperMarker] = useState(false);
    const [newCrapperCoords, setNewCrapperCoords] = useState(initCoords);
    const [addCrapperOpen, setAddCrapperOpen] = useState(false);
    const [crapperBeingEdited, setCrapperBeingEdited] = useState(null);

    const mapBounds = useRef();
    const filterValues = useRef();
    const showNewCrapperMarkerRef = useRef(false);

    const getCrappers = async (center) => {
        setUpdatingCrappers(true);
        let queryStr = 'mapbounds?';

        if (center?.latitude != null)
            queryStr += 'centerlat=' + String(center.latitude) + '&';

        if (center?.longitude != null)
            queryStr += 'centerlng=' + String(center.longitude) + '&';

        if (mapBounds.current?._southWest?.lat != null)
            queryStr += 'swlat=' + mapBounds.current?._southWest?.lat + '&';;

        if (mapBounds.current?._southWest?.lng != null)
            queryStr += 'swlng=' + mapBounds.current?._southWest?.lng + '&';

        if (mapBounds.current?._northEast?.lat != null)
            queryStr += 'nelat=' + mapBounds.current?._northEast?.lat + '&';

        if (mapBounds.current?._northEast?.lng != null)
            queryStr += 'nelng=' + mapBounds.current?._northEast?.lng;

        if (queryStr.substring(queryStr.length - 1, 1) == '&')
            queryStr = queryStr.substring(0, queryStr.length - 2);

        const response = await fetch('api/bathroom/' + queryStr);

        if (response.status == 200) {
            const crappers = await response?.json();
            let filteredCrappers = FilterCrappers(crappers);

            setCrappers(filteredCrappers);
            setClusterKey(clusterKey => clusterKey + 1);
        }

        setUpdatingCrappers(false);
    }

    const FilterCrappers = (crappers) => {
        if (crappers != null && filterValues.current != null) {
            //convert to an array
            let crapperArray = Object.entries(crappers);

            //filter
            let filtered = crapperArray.filter(([key, val]) => {
                if (val.rating < filterValues.current.MinRating)
                    return false;

                if (filterValues.current.Multistall == true && val.multistall != true)
                    return false;

                if (filterValues.current.SeparateMens == true && val.separateMens != true)
                    return false;

                if (filterValues.current.AllGender == true && val.allGender != true)
                    return false;

                if (filterValues.current.HandicapAccessible == true && val.handicapAccessible != true)
                    return false;

                if (filterValues.current.BabyChangeStation == true && val.babyChangeStation != true)
                    return false;

                if (filterValues.current.Shower == true && val.shower != true)
                    return false;

                if (filterValues.current.Fee == true && val.fee != true)
                    return false;

                return true;
            });

            //convert array back to object
            return Object.fromEntries(filtered);
        }
        else {
            return crappers;
        }
    }

    const onSearchClick = async () => {
        await getCrappers();
        setShowSearchButton(false);
    }

    const onQueryOverpassClick = async () => {
        let queryStr = 'mapbounds?';

        if (mapBounds.current?._southWest?.lat != null)
            queryStr += 'swlat=' + mapBounds.current?._southWest?.lat + '&';

        if (mapBounds.current?._southWest?.lng != null)
            queryStr += 'swlng=' + mapBounds.current?._southWest?.lng + '&';

        if (mapBounds.current?._northEast?.lat != null)
            queryStr += 'nelat=' + mapBounds.current?._northEast?.lat + '&';

        if (mapBounds.current?._northEast?.lng != null)
            queryStr += 'nelng=' + mapBounds.current?._northEast?.lng;

        if (queryStr.substring(queryStr.length - 1, 1) == '&')
            queryStr = queryStr.substring(0, queryStr.length - 2);

        const response = await fetch('api/overpass/' + queryStr);

        if (response.status == 200) {
            await getCrappers();
        }
    }

    const triggerRefresh = async () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            //setUserCoords({ lat: position.coords.latitude, lng: position.coords.longitude });
            getCrappers(position.coords);
        });
    }

    const onRatingSubmit = async () => {
        await getCrappers();
    }

    const goToUserLocation = async () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setUserCoords({ lat: position.coords.latitude, lng: position.coords.longitude });

            let coords = [position.coords.latitude, position.coords.longitude];
            map.setView(coords, 16);

            getCrappers(position.coords);
        }
        );
    }

    const getMapBounds = () => {

        let currentMapBounds = map.getBounds();
        let mapzoom = map.getZoom();

        mapBounds.current = currentMapBounds;

        //if at a reasonable zoom, and currently not updating crappers, auto-fetch crappers
        if (mapzoom > 10 && !updatingCrappers) {
            setShowZoomInMessage(false);
            getCrappers();
        } else {
            //setShowSearchButton(true);
        }

        if (mapzoom <= 10)
            setShowZoomInMessage(true);
    };

    const onApplyFilter = async (values) => {
        if (values != null) {
            filterValues.current = values;

            if (mapBounds.current == null) {
                mapBounds.current = map.getBounds();
            }

            await getCrappers();
        }
    }

    const onSearchResultsReceived = (place) => {
        var coords = { lat: place.lat, lng: place.lon };
        map.setView(coords, 16);
    }

    const onGooglePlaceSelected = (coords) => {
        if (coords != null)
            map.setView(coords, 20);
    }

    const toggleCloseAddCrapper = (editingLocation) => {
        if (!editingLocation) {
            setCrapperBeingEdited(null);
            toggleShowNewCrapperMarker(false);
        }
        setAddCrapperOpen(false);
    }

    const toggleOpenAddCrapper = () => {
        //setCrapperBeingEdited(crapper);
        setAddCrapperOpen(true);
    }

    const onNewCrapperCoordsSave = () => {       
        let crapper = { bathroomId: 0, gpslat: newCrapperCoords.lat, gpslong: newCrapperCoords.lng };
        setCrapperBeingEdited({ crapper: crapper });
        toggleOpenAddCrapper();
    }

    const onNewCrapperCoordsCancel = () => {
        toggleShowNewCrapperMarker(false);
        setCrapperBeingEdited(null);
    }

    const onAddCrapperClick = () => {
        let coords = map.getCenter();
        if (coords != null) {
            map.setView(coords, 20);
            setNewCrapperCoords(coords);
            toggleShowNewCrapperMarker(true);
        }
    }

    const onDragEnd = () => {
        getMapBounds();
    }

    const onMapMove = () => {
        if (showNewCrapperMarkerRef.current == true) {
            let coords = map.getCenter();
            if (coords != null) {
                setNewCrapperCoords(coords);
            }
        }
    }

    const toggleShowNewCrapperMarker = (show) => {
        setShowNewCrapperMarker(show);
        showNewCrapperMarkerRef.current = show;
    }

    const addCrapperEditLocationClick = (coords) => {
        toggleCloseAddCrapper();
        if (coords != null) {
            map.setView(coords, 20);
            setNewCrapperCoords(coords);
            toggleShowNewCrapperMarker(true);
        }
    }

    const onEditCrapperClicked = (crapper) => {
        setCrapperBeingEdited({ crapper: crapper });
        toggleOpenAddCrapper();
    }

    const onZoomInToSeeCrappersClick = () => {
        let coords = map.getCenter();
        if (coords != null) {
            map.setView(coords, 12);
        }
    }

    useEffect(() => {
        //run right away when entering page, or if online status changes
        if (isOnline) {
            goToUserLocation();
        }
    }, [isOnline]);

    if (!map.hasEventListeners('dragend'))
        map.on('dragend', onDragEnd);

    if (!map.hasEventListeners('zoomend'))
        map.on('zoomend', getMapBounds);

    if (!map.hasEventListeners('move'))
        map.on('move', onMapMove);

    return (
        <div>
            <div className='position-absolute' style={{ top: '10px', right: '10px', zIndex: '10001' }}>
                <button className="btn btn-light shadow-lg rounded-circle" onClick={goToUserLocation}>
                    <span style={{ color: 'dodgerblue' }}><i className="fas fa-location-arrow"></i></span>
                </button>
            </div>
            <div className='position-relative text-center' style={{ top: '10px', width: '55%', zIndex: '10000', marginLeft: 'auto', marginRight: 'auto' }}>
                <GooglePlaceSearchBox placeSelected={onGooglePlaceSelected} />
            </div>
            {showZoomInMessage && !showSearchButton &&
                <div className='position-relative text-center' style={{ top: '30px', width: '75%', zIndex: '10000', marginLeft: 'auto', marginRight: 'auto' }}>
                    <button className='btn btn-light shadow-lg rounded-pill' onClick={onZoomInToSeeCrappersClick}>Zoom In to See Crappers</button>
                </div>
            }
            {showSearchButton &&
                <div className='position-relative text-center' style={{ top: '10px', width: '75%', zIndex: '10000', marginLeft: 'auto', marginRight: 'auto' }}>
                <button className='btn btn-secondary shadow-lg rounded-pill bg-gray-transition' onClick={onSearchClick} size='sm'>
                {updatingCrappers ?
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">...</span>
                    </div>
                    :
                    'Search This Area'
                }
                </button>
                </div>
            }
            <UserMarker position={[userCoords.lat, userCoords.lng]} />
            {!showNewCrapperMarker &&
                <MarkerClusterGroup key={clusterKey} maxClusterRadius={20} removeOutsideVisibleBounds={false}>
                    {Object.values(crappers).map((crapper) =>
                        <CrapperMarker key={crapper.bathroomId} Crapper={crapper} onRatingSubmit={onRatingSubmit} onEditCrapperClicked={onEditCrapperClicked} />
                    )}
                </MarkerClusterGroup>
            }
            {showNewCrapperMarker &&
                <NewCrapperMarker            
                position={[newCrapperCoords.lat, newCrapperCoords.lng]}
                >
                </NewCrapperMarker>
            }
            <div className="position-absolute text-center" style={{ bottom: '20px', zIndex: '1000', width: '100%' }}>
                {showNewCrapperMarker ?
                    <div className="d-flex justify-content-center mb-5">
                        <button className="btn btn-success btn-lg" style={{marginRight: '20px'}} onClick={onNewCrapperCoordsSave}>Save</button>
                        <button className="btn btn-danger btn-lg" style={{ marginLeft: '20px' }} onClick={onNewCrapperCoordsCancel}>Cancel</button>
                    </div>
                    :
                    <div className="d-flex justify-content-around">
                        {isLoggedIn ?
                            <button className="btn btn-primary shadow-lg rounded-pill" onClick={onAddCrapperClick}>Add Crapper</button>
                            :
                            <button disabled className="btn btn-primary shadow-lg rounded-pill">Add Crapper</button>
                        }
                        <FilterButton onApplyFilter={onApplyFilter} />
                    </div>
                }
                { !isLoggedIn &&
                    <div className="mt-2">{registerLink} or {loginLink} to create and review crappers!</div>
                }
            </div>
            <AddCrapperForm
                isOpen={addCrapperOpen}
                toggleOpen={toggleOpenAddCrapper}
                toggleClosed={toggleCloseAddCrapper}
                crapperAndRating={crapperBeingEdited}
                onRatingSubmit={onRatingSubmit}
                onCancel={null}
                onEditLocationClick={addCrapperEditLocationClick}
            />
        </div>
    );
}