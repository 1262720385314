import { parseJSON } from 'jquery';
import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './authorization/UserProvider';

export const Profile = (props) => {
    const [formValues, setValues] = useState({
        username: '',
        email: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        apiresponse: ''
    });

    const { user, checkLogin } = useContext(UserContext);

    const handleInputChange = (e) => {
        setErrors({ ...errors, apiresponse: '' });

        const value = e.target.value;

        if (e.target.name == 'username') {
            validateUsername(value);
        }
        if (e.target.name == 'email') {
            validateEmail(value);
        }

        setValues({ ...formValues, [e.target.name]: value });
    }

    const validateUsername = (value) => {
        if (value.length == 0) {
            setErrors({ ...errors, username: 'user name required!' });
            return false;
        }
        else {
            setErrors({ ...errors, username: '' });
            return true;
        }
    }

    const validateEmail = (value) => {
        if (value.length == 0) {
            setErrors({ ...errors, email: 'email required!' });
            return false;
        }
        else {
            setErrors({ ...errors, email: '' });
            return true;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        //first round validation
        let valid = validateUsername(formValues.username) &&
            validateEmail(formValues.email)

        if (valid) {
            //call to register
            EditUser();
        }
    }

    const getUser = async () => {

        const response = await fetch('api/authenticate/profile');

        const data = await response?.json();

        setValues({ username: data?.username, email: data?.email });
    }

    const EditUser = async () => {
        var profileModel = formValues;

        const postOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(profileModel)
        };

        const response = await fetch('api/authenticate/profile', postOptions);

        if (response.status == 200) {

            //const userInfo = {
            //    username: formValues.username,
            //    isLoggedIn: true
            //};
            //updateUser(userInfo);
            await checkLogin();
            props.history.push('/');
        }
        else {
            response.text().then(value => {
                //alert(parseJSON(value)?.message);
                let err = parseJSON(value)?.message;
                if (err == null) {
                    err = 'Register Failed. Make sure of the following:'
                        + '\n-Username, Email address are required.'
                        + '\n-Username should only have alphanumeric characters and underscores.';
                }
                setErrors({ ...errors, apiresponse: err });
            }
            );
        }
    }

    const onDeleteProfile = async () => {
        const r = window.confirm("Are you sure you want to delete your profile?!");
        if (r == false)
            return;

        const postOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch('api/authenticate/', postOptions);

        if (response.status == 200) {
            props.history.push('/authorization/Logout');
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    useEffect(() => {
        if (user == null || user.isLoggedIn == false) {
            props.history.push('/');
            return;
        }

        getUser();
    }, []);

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    <h4>Profile</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Edit Username:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Enter User Name"
                                value={formValues.username}
                                onChange={handleInputChange}>
                            </input>
                            <span style={{ color: "red" }}>{errors.username}</span>
                        </div>
                        <div className="form-group mt-3">
                            <label>Edit Email Address:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                placeholder="Enter Email"
                                value={formValues.email}
                                onChange={handleInputChange}>
                            </input>
                            <span style={{ color: "red" }}>{errors.email}</span>
                        </div>
                        <div className="container mt-3">
                            <div className="d-flex justify-content-around">
                                <button type="submit" className="btn btn-primary">Submit</button>
                                <Link className="btn btn-secondary" to={{ pathname: "/" }}>Cancel</Link>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <span style={{ color: "red" }}>{errors.apiresponse}</span>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card bg-light mt-3">
                <div className="p-3 text-center">
                    <button className="btn btn-danger btn-sm" onClick={onDeleteProfile}>Delete Profile</button>
                </div>
            </div>
        </div>
    );
}