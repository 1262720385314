import React from 'react';
import ribbon from '../symbols/poochievements/ribbon.png';
import poofessional from '../symbols/poochievements/poofessional.png';
import distinguished from '../symbols/poochievements/distinguished.png';
import certified from '../symbols/poochievements/certified.png';
import tenured from '../symbols/poochievements/tenured.png';
import heroic from '../symbols/poochievements/heroic.png';

export const Poochievement = (props) => {

    let poochievement;
    let name;
    let description;
    let image;
    switch (props.type) {
        case 1:
            image = <Pooticipation />;
            name = "Poo-ticipation Award";
            description = "You've submitted your first Rating! Submit more ratings to acquire more poochievements!";
            break;
        case 2:
            image = <PooingSpree />;
            name = "Pooing Spree!";
            description = "You've submitted 5 ratings this month!";
            break;
        case 3:
            image = <PooingFrenzy />;
            name = "Pooing Frenzy!";
            description = "You've submitted 10 ratings this month!";
            break;
        case 4:
            image = <PooingRiot />;
            name = "Pooing Riot!";
            description = "You've sumitted 15 ratings this month!";
            break;
        case 5:
            image = <PooingRampage />;
            name = "Pooing Rampage!";
            description = "You're on a role! You've submitted 20 ratings this month!";
            break;
        case 6:
            image = <InterstateCrapper />;
            name = "Inter-state Crapper";
            description = "You've submitted ratings in multiple states!";
            break;
        case 7:
            image = <InternationalCrapper />;
            name = "International Crapper!";
            description = "Your pooing prowess knows no bounds! You've submitted ratings in multiple countries!";
            break;
        case 8:
            image = <CertifiedCrapper />;
            name = "Certified Crapper";
            description = "You've earned a stamp of apooval. You can put this certification on your resume! You've submitted 5 total ratings!";
            break;
        case 9:
            image = <DistinguishedCrapper />;
            name = "Distinguished Crapper";
            description = "Your poo-complishments have earned you the respect of poo-nation. You've submitted 10 total ratings!";
            break;
        case 10:
            image = <Poofessional />;
            name = "Poofessional";
            description = "Congratulations on becoming a Poofessional! They say do what you love and you'll never work a day in your life. You've submitted 15 total ratings!";
            break;
        case 11:
            image = <TenuredCrapper />;
            name = "Tenured Crapper";
            description = "Your pooing mastery has been recognized. You're essentially unfireable now. You've submitted 25 total ratings!";
            break;
        case 12:
            image = <HeroicCrapper />;
            name = "Heroic Crapper";
            description = "You've been decorated for your valor and determination to keep poo-nation informed. You've submitted 50 total ratings!";
            break;
        case 13:
            image = <LegendaryCrapper />;
            name = "Legendary Crapper";
            description = "Songs and books will be written about your epic poo-liferation. You've earned a spot at the Gates of Poo-halla. You've submitted 100 total ratings!";
            break;
        default:
            image = null;
            name = "";
            description = "";
            break;
    }

    return (
        <div>
            {image}
            <span className='d-block'><b>{name}</b></span>
            <span className='d-block mt-2'>{description}</span>
        </div>
    );
}

const Pooticipation = () => {
    return (
        <img src={ribbon} width='Auto' />
    );
}

const CertifiedCrapper = () => {
    return (
        <img src={certified} width='Auto' />
    );
}

const DistinguishedCrapper = () => {
    return (
        <img src={distinguished} width='Auto' />
    );
}

const Poofessional = () => {
    return (
        <img src={poofessional} width='Auto' />
    );
}

const TenuredCrapper = () => {
    return (
        <img src={tenured} width='Auto' />
    );
}

const HeroicCrapper = () => {
    return (
        <img src={heroic} width='Auto' />
    );
}

const LegendaryCrapper = () => {
    return (
        <i className="fa-solid fa-crown"></i>
    );
}

const PooingSpree = () => {
    return (
        <i className="fa-brands fa-empire fa-5x"></i>       
    );
}

const PooingFrenzy = () => {
    return (
        <i className="fa-brands fa-centos"></i>
    );
}

const PooingRiot = () => {
    return (
        <i className="fa-brands fa-mandalorian"></i>
    );
}

const PooingRampage = () => {
    return (
        <i className="fa-solid fa-biohazard"></i>
    );
}

const InterstateCrapper = () => {
    return (
        <img src={ribbon} width='Auto' />
    );
}

const InternationalCrapper = () => {
    return (
        <i className="fa-solid fa-earth-americas"></i>
    );
}