import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { InstagramEmbed } from 'react-social-media-embed';

export const Notification = (props) => {
    const popupOpen = props.isOpen;
    const okClick = props.okClick;
    const notification = props.notification;

    let title = '';
    let message = '';
    let image = '';
    let id = 0;
    let type = 0;

    if (notification != null) {
        id = notification.notificationId;
        title = notification.title;
        message = notification.message;
        image = notification.imageUrl;
        type = notification.type;
    }

    return (
        <Modal zIndex='50002' isOpen={popupOpen} toggle={okClick}>
            <ModalHeader className='bg-light' toggle={okClick}>
                {title}
            </ModalHeader>
            <ModalBody>
                <div className='text-center'>
                    <span className='mb-3' style={{display: 'block'}}>{message}</span>
                    {type == 1 &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={image} width="auto" />
                        </div>
                    }
                    {type == 2 &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InstagramEmbed captioned="false" url={image} style={{maxWidth: 500}} width="100%" />
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="container">
                    <div className="d-flex justify-content-around">
                        <button className="btn btn-primary" onClick={okClick}>Ok</button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>      
    );
}