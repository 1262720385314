import React, { useEffect, useState } from 'react';
import { Comment } from './Comment';
import { SetRating } from './SetRating';

export const Reviews = (props) => {
    const crapper = props.crapper;
    const bathId = crapper?.bathroomId ?? 0;
    const [ratings, setRatings] = useState({});
    const [submitRatingVisible, setSubmitRatingVisible] = useState(false);
    const [ratingToEdit, setRatingToEdit] = useState({});

    useEffect(() => {
        getRatings();
    }, []);

    const onShowSubmitRatingClick = () => {
        setSubmitRatingVisible(!submitRatingVisible);
    }

    const refreshRatings = () => {
        getRatings();
    }

    const getRatings = async (/*args*/) => {
        const response = await fetch('api/rating/' + bathId);

        const data = await response?.json();

        setRatings(data);
    }

    const onEditRatingClick = (rating) => {
        setRatingToEdit(rating);
        setSubmitRatingVisible(!submitRatingVisible);
    }

    return (
        <div>
            {Object.values(ratings).map((ratingInfo) =>
                <Comment key={ratingInfo.ratingId} rating={ratingInfo} onEditRatingClickFromComments={onEditRatingClick} />
            )}
            <SetRating isOpen={submitRatingVisible} toggle={onShowSubmitRatingClick} crapper={crapper} ratingToEdit={ratingToEdit} onRatingSubmit={refreshRatings} onCancel={onShowSubmitRatingClick} />
        </div>
    );
}