import React from 'react';
import mensIcon from '../symbols/mens svg 1inch.svg'; 
import womensIcon from '../symbols/womens svg 1inch.svg';
import allgenderIcon from '../symbols/allgender svg 1inch.svg';
import handicapIcon from '../symbols/handicap svg 1inch.svg';
import { divIcon } from 'leaflet';

export const Amenities = (props) => {
    let crapper = props.crapper;
    let content = [];
    let i = 0;

    if (crapper.separateMens) {
        content.push(<MensIcon key={i++} />);
        content.push(<WomensIcon key={i++} />);
    }
    //if (crapper.separateWomens) {
    //    content.push(<WomensIcon key={i++} />);
    //}
    if (crapper.allGender) {
        content.push(<AllGenderIcon key={i++} />);
    }
    if (crapper.handicapAccessible) {
        content.push(<HandicapIcon key={i++} />);
    }
    if (crapper.babyChangeStation) {
        content.push(<BabyIcon key={i++} />);
    }
    if (crapper.fee) {
        content.push(<FeeIcon key={i++} />);
    }
    if (crapper.shower) {
        content.push(<ShowerIcon key={i++} />);
    }
    return (
        <div className="AmenitiesGroup">
            {content}
        </div>
    );
}

const MensIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-male fa-2x"></i>
        </div>
    );
}

const WomensIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-female fa-2x"></i>
        </div>
    );
}

const HandicapIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-wheelchair fa-2x"></i>
        </div>
    );
}

const AllGenderIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-male fa-2x"></i>
            <i className="fas fa-female fa-2x"></i>
        </div>
    );
}

const FeeIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-dollar-sign fa-2x"></i>
        </div>
    );
}

const ShowerIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-shower fa-2x"></i>
        </div>
    );
}

const BabyIcon = () => {
    return (
        <div className="AmenitiesIcon">
            <i className="fas fa-baby fa-2x"></i>
        </div>
    );
}