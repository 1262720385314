import React, { Component, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from './Rating';
import { Button } from 'reactstrap';
import { UserContext } from './authorization/UserProvider';

export const ReportBug = (props) => {

    const [bugMessage, setBugMessage] = useState('');
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user == null || user.isLoggedIn == false) {
            props.history.push('/');
            return;
        }
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;

        setBugMessage(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        SubmitBug();
    }

    const SubmitBug = async () => {
        var postheaders = { 'Content-Type': 'application/json' }

        var bugReportModel = { BugReportMessage: bugMessage };

        const postOptions = {
            method: 'POST',
            headers: postheaders,
            body: JSON.stringify(bugReportModel)
        };

        const response = await fetch('api/bugreport', postOptions);
       
        if (response.status == 200) {
            alert('Bug Report Submitted!');
            props.history.push('/');
        }
        else {
            response.text().then(text => alert(text));
        }
        
    }

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    <h4>Report a Bug!</h4>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Let us know how we can improve the experience!</label>
                            <textarea
                                wrap="soft"
                                className="form-control mt-2"
                                name="Comments"
                                placeholder="Enter Details About the Issue"
                                value={bugMessage}
                                onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="d-flex justify-content-around mt-3">
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <Link className="btn btn-secondary" to={{ pathname: "/" }}>Cancel</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}