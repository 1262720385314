import React, { useState, createContext } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as confetti from 'canvas-confetti';
import { Poochievement } from './Poochievement';
import { useSound } from './SoundProvider';

export const PoochievementNotificationContext = createContext();

export const PoochievementNotification = ({children}) => {
    const [pooChievements, setPoochievements] = useState({})
    const [popupOpen, setPopupOpen] = useState(false);
    const audioElement = useSound();
    const yay = audioElement?.yay;

    const throwConfetti = () => {
        var canvas = document.getElementById("canvasId");
        var myConfetti = confetti.create(canvas, { resize: true });
        myConfetti();
        yay.play();
    }

    const okClick = () => {
        setPopupOpen(false);
    }

    const CheckForNewPoochievements = async (/*args*/) => {
        const response = await fetch('api/poochievements/new');

        if (response.status == 200) {
            const data = await response?.json();

            if (data != null && data.length > 0) {
                setPoochievements(data);
                setPopupOpen(true);
                return true;
            }
        }

        setPoochievements({});
        setPopupOpen(false);
        return false;
    }

    return (
        <PoochievementNotificationContext.Provider value={{ CheckForNewPoochievements }}>
            <Modal zIndex='50001' onOpened={throwConfetti} isOpen={popupOpen}>
                <div className="bg-light modal-header">
                    <h5 className="w-100 text-center">Poochievements Acquired!</h5>
                </div>
                <ModalBody>
                    <div className='card bg-light'>
                        <div className='card-body'>
                        <canvas className='position-absolute w-100 h-100' id="canvasId">
                        </canvas>
                            {Object.values(pooChievements).map((pooChievement, index) =>
                                <div key={index} className='mb-3 text-center'>
                                <Poochievement key={pooChievement.type} type={pooChievement.type} />
                            </div>
                        )}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="container">
                        <div className="d-flex justify-content-around">
                            <button className="btn btn-primary" onClick={okClick}>Ok</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            {children}
        </PoochievementNotificationContext.Provider>
    );
}