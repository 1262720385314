import React, { Component, useState, useEffect, useContext } from 'react';
import { Rating } from './Rating';
import { useLocation, Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Confirmation } from './Confirmation';
import { useSound } from './SoundProvider';
import { ServiceWorkerContext } from './ServiceWorkerProvider';
import { PoochievementNotificationContext } from './PoochievementNotification';

export const SetRating = (props) => {

    const crapper = props.crapper;
    const ratingToEdit = props.ratingToEdit;
    const defaultValues = {
        Multistall: crapper?.multistall,
        SeparateMens: crapper?.separateMens,
        AllGender: crapper?.allGender,
        HandicapAccessible: crapper?.handicapAccessible,
        BabyChangingStation: crapper?.babyChangeStation,
        Shower: crapper?.shower,
        Fee: crapper?.fee,
        Rating: ratingToEdit?.rating ?? 0,
        Comments: ratingToEdit?.comment ?? ''
    };

    const swVersion = useContext(ServiceWorkerContext);

    const [formValues, setValues] = useState(defaultValues);

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const toggleConfirmation = () => {
        setConfirmationOpen(!confirmationOpen);
    }

    const audioElement = useSound();
    const fart = audioElement?.fart;

    const pooChievementsObject = useContext(PoochievementNotificationContext);

    useEffect(() => {
        if (props.isOpen) {
            setValues(defaultValues);
        }
    }, [props.isOpen]);

    const onStarClick = (starNum) => {
        setValues({ ...formValues, Rating: starNum });
    }

    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setValues({ ...formValues, [e.target.name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let success = await setRating();

        if (success) {
            toggleConfirmation(); //open confirmation
            props.toggle(); //toggle self closed
        }
    }

    const onCancelClick = () => {
        props.onCancel();
    }

    const confirmationOkClicked = () => {
        toggleConfirmation();
        props.onRatingSubmit();
    }

    const setRating = async () => {
        setSaving(true);

        let success = false;

        var postheaders = { 'Content-Type': 'application/json' }

        var bath = crapper;
        bath.multistall = formValues.Multistall;
        bath.separateMens = formValues.SeparateMens;
        bath.handicapAccessible = formValues.HandicapAccessible;
        bath.babyChangeStation = formValues.BabyChangingStation;
        bath.shower = formValues.Shower;
        bath.fee = formValues.Fee;
        bath.allGender = formValues.AllGender;

        var rating = {
            RatingId: ratingToEdit?.ratingId ?? 0,
            Rating: formValues.Rating,
            Comment: formValues.Comments
        };

        var viewModel = { bathroom: bath, rating: rating, swVersion: swVersion };

        const postOptions = {
            method: 'PUT',
            headers: postheaders,
            body: JSON.stringify(viewModel)
        };

        const response = await fetch('api/bathroom', postOptions);

        if (response.status == 200) {
            let data = await response.json();
            success = true;
            let didUserGetPoochievements = await pooChievementsObject?.CheckForNewPoochievements();

            //only play the fart if the user didn't get any poochievements from this rating
            if (didUserGetPoochievements == null || didUserGetPoochievements == false) {
                fart.play(); //play fart
            }
        }
        else {
            success = false;
            response.text().then(text => alert(text));
        }

        setSaving(false);

        return success;
    }

    const onDeleteRating = async () => {
        if (ratingToEdit == null)
            return;

        const r = window.confirm("Are you sure you want to delete this rating?!");
        if (r == false)
            return;

        const postOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        };

        const response = await fetch('api/rating/' + ratingToEdit.ratingId, postOptions);

        if (response.status == 200) {
            props.toggle(); //toggle self closed
            props.onRatingSubmit(); 
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    return (
        <div>
            <Modal zIndex="20003" isOpen={props.isOpen} toggle={() => props.toggle()}>
                <ModalHeader className="bg-light">{crapper.locationName}</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <div className="card bg-light">
                            <div className="card-body">
                                <div className="form-group">
                                    <label>How to you rate your experience?</label>
                                    <Rating
                                        rating={formValues.Rating}
                                        canEdit={true}
                                        onStarClick={onStarClick} />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Talk about your experience:</label>
                                    <textarea
                                        wrap="soft"
                                        className="form-control"
                                        name="Comments"
                                        placeholder="Enter comments"
                                        value={formValues.Comments}
                                        onChange={handleInputChange}>
                                    </textarea>
                                </div>
                                <div className="form-check mt-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="Multistall"
                                        checked={formValues.Multistall}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Multi-stall</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="SeparateMens"
                                        checked={formValues.SeparateMens}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Separate Womens & Mens</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="AllGender"
                                        checked={formValues.AllGender}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">All Gender</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="HandicapAccessible"
                                        checked={formValues.HandicapAccessible}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Handicap Accessible</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="BabyChangingStation"
                                        checked={formValues.BabyChangingStation}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Baby Changing Station</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="Shower"
                                        checked={formValues.Shower}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Shower</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="Fee"
                                        checked={formValues.Fee}
                                        onChange={handleInputChange}>
                                    </input>
                                    <label className="form-check-label">Fee</label>
                                            </div>
                            </div>
                        </div>
                        <div className="card bg-light mt-3">
                            <div className="card-body">
                                <div className="d-flex justify-content-around">
                                    {saving ?
                                        <button disabled className="btn btn-primary">Saving...</button>
                                        :
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    }
                                    <button type="button" className="btn btn-secondary" onClick={onCancelClick}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {ratingToEdit != null &&
                        <div className="card bg-light mt-3">
                            <div className="card-body">
                                <div className="text-center">
                                <button className="btn btn-danger btn-sm" onClick={onDeleteRating}>Delete Rating</button>
                                </div>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
            <Confirmation isOpen={confirmationOpen} toggle={confirmationOkClicked} okClick={confirmationOkClicked} message={'Rating Submitted!'} />
        </div>
            );
}