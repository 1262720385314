import React, { Component } from 'react';
import { Route } from 'react-router';
import { OnlineStatusProvider } from './components/OnlineStatusProvider';
import { Layout } from './components/Layout';
import { ReportBug } from './components/ReportBug';
import { Register } from './components/authorization/Register';
import { Logout } from './components/authorization/Logout';
import { UserProvider } from './components/authorization/UserProvider';
import { ForgotPassword } from './components/authorization/ForgotPassword';
import { ResetPassword } from './components/authorization/ResetPassword';
import { Home } from './components/Home';
import { Privacy } from './components/Privacy';
import { Profile } from './components/Profile';
import { UserManager } from './components/authorization/UserManager';
import './custom.css';
import { SoundProvider } from './components/SoundProvider';
import { ServiceWorkerProvider } from './components/ServiceWorkerProvider';
import { Poochievements } from './components/Poochievements';
import { PoochievementNotification } from './components/PoochievementNotification';
import { GoogleResponse } from './components/authorization/GoogleResponse';
import { AppleResponse } from './components/authorization/AppleResponse';
import { NotificationProvider } from './components/NotificationProvider';

const App = () => {
    const displayName = App.name;
            
    return (
    <UserProvider>
        <OnlineStatusProvider>
            <SoundProvider>
                <ServiceWorkerProvider>
                    <NotificationProvider>
                    <PoochievementNotification>
                        <Layout>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/authorization/Register' component={Register} />
                            <Route exact path='/authorization/Logout' component={Logout} />
                            <Route exact path='/authorization/ForgotPassword' component={ForgotPassword} />
                            <Route exact path='/authorization/ResetPassword' component={ResetPassword} />
                            <Route exact path='/authorization/UserManager' component={UserManager} />
                            <Route exact path='/Privacy' component={Privacy} />
                            <Route exact path='/Profile' component={Profile} />
                            <Route path='/ReportBug' component={ReportBug} />
                            <Route path='/Poochievements' component={Poochievements} />
                            <Route path='/identity/signin-google' component={GoogleResponse} />
                            <Route path='/authorization/appleresponse' component={AppleResponse} />
                        </Layout>
                    </PoochievementNotification>
                    </NotificationProvider >
                </ServiceWorkerProvider>
            </SoundProvider>
        </OnlineStatusProvider>
    </UserProvider>
    );
}

export default App;
