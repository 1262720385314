import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from './Rating';
import { Button } from 'reactstrap';
import { UserContext } from './authorization/UserProvider';

export const ReportCrapper = (props) => {
    const crapperId = props.crapperId;
    const [reportMessage, setReportMessage] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;

        setReportMessage(value);
    }

    const onCancelClick = () => {
        props.onCancel();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        SubmitReport();
    }

    const SubmitReport = async () => {
        var postheaders = { 'Content-Type': 'application/json' }

        var reportCrapperModel = { CrapperId: crapperId, ReportCrapperMessage: reportMessage };

        const postOptions = {
            method: 'POST',
            headers: postheaders,
            body: JSON.stringify(reportCrapperModel)
        };

        const response = await fetch('api/reportcrapper', postOptions);

        if (response.status == 200) {
            alert('Submitted!');
            props.onReportSubmit();
        }
        else {
            response.text().then(text => alert(text));
        }

    }

    return (
        <div className="container">
            <div className="card bg-light">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label></label>
                            <textarea
                                wrap="soft"
                                className="form-control mt-2"
                                name="Comments"
                                placeholder="Report or Suggest an Edit to this Crapper!"
                                value={reportMessage}
                                onChange={handleInputChange}>
                            </textarea>
                        </div>
                        <div className="d-flex justify-content-around mt-3">
                            <button type="submit" className="btn btn-primary">Submit</button>
                            <button type="button" className="btn btn-secondary" onClick={onCancelClick}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}