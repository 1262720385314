import React, { Component, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from './UserProvider';

export const UserManager = (props) => {
    const [role, setRole] = useState('');
    const [roles, setRoles] = useState({});
    const [users, setUsers] = useState({});

    const { user } = useContext(UserContext);

    const handleInputChange = (e) => {
        const value = e.target.value;

        setRole(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (role != '')
            CreateRole();

    }

    const CreateRole = async () => {
        var roleModel = { Name: role };

        const postOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(roleModel)
        };

        const response = await fetch('api/authenticate/role', postOptions);

        if (response.status == 200) {
            alert("success");
            GetRoles();
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    const GetRoles = async () => {
        const response = await fetch('api/authenticate/roles');

        if (response.status == 200) {
            const roles = await response?.json();
            setRoles(roles);
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    const GetUsers = async () => {
        const response = await fetch('api/authenticate/usersdata');

        if (response.status == 200) {
            const users = await response?.json();
        
            setUsers(users);
        }
        else {
            response.text().then(text => alert(text));
        }
    }

    useEffect(() => {
        GetRoles();
        GetUsers();
    }, []);

    return (
        <div className="container">
            <div className="card bg-light mt-3">
                <div className="card-header">
                    Users (count: {users?.length})
                </div>
                <div className="card-body">
                    <table style={{fontSize: '10px'}}>
                        <tr>
                            <th>User</th>
                            <th>Role</th>
                            <th>#Ratings</th>
                            <th>#Crappers</th>
                        </tr>
                        {Object.values(users).map((_user) =>
                            <tr>
                                <td>{_user.userName}</td>
                                <td>{_user.role}</td>
                                <td>{_user.numRatings}</td>
                                <td>{_user.numCrappersCreated}</td>
                            </tr>
                        )}
                    </table>
                </div>
            </div>
            <div className="card bg-light mt-3">
                <div className="card-header">Roles</div>
                <div className="card-body">
                    <div className="mt-3">
                        {Object.values(roles).map((_role) =>
                            <div>{_role.name}</div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit} className="mt-3">
                        <div className="form-group">
                            <label>Enter New Role:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="role"
                                placeholder="Enter Role"
                                onChange={handleInputChange}>
                            </input>
                        </div>
                        <div className="container mt-4">
                            <div className="d-flex justify-content-around">
                                <button type="submit" className="btn btn-primary">Submit</button>
                                <Link className="btn btn-danger" to={{ pathname: "/" }}>Cancel</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}