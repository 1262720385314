import React, { Component } from 'react';
import { ExternalSigninButton } from './ExternalSigninButton';

export const AppleSigninButton = (props) => {
    const height = props.height;
    const width = props.width;
    const buttonText = 'Sign in with Apple';
    const logo = 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg'

    return (
        <ExternalSigninButton
            width={props.width}
            height={props.height}
            logo={logo}
            text={buttonText}
            onClick={props.onClick}/>
    );
}

