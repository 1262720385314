import React, { useEffect, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Rating } from './Rating';

export const FilterButton = (props) => {
    const defaultValues =
    {
        MinRating: -1,
        Multistall: false,
        SeparateMens: false,
        AllGender: false,
        HandicapAccessible: false,
        BabyChangeStation: false,
        Shower: false,
        Fee: false,
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [filterValues, setFilterValues] = useState(defaultValues);

    const onShowFilterClick = () => {
        setFilterVisible(!filterVisible);
    }

    const onStarClick = (starNum) => {
        setFilterValues({ ...filterValues, MinRating: starNum });
    }

    const onApplyFilterClick = async () => {
        setInProgress(true);
        setFilterVisible(false);

        await props.onApplyFilter(filterValues);
        
        setInProgress(false);

    }

    const onClearFilter = async () => {
        setInProgress(true);

        setFilterValues(defaultValues);
        setFilterVisible(false);

        await props.onApplyFilter(defaultValues);
        
        setInProgress(false);
    }

    const handleInputChange = (e) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setFilterValues({ ...filterValues, [e.target.name]: value });
    }

    useEffect(() => {

    }, []);

    return (
        <button className="btn btn-secondary shadow-lg rounded-pill" onClick={onShowFilterClick}>
            {inProgress &&
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">...</span>
                </div>
            }
            {!inProgress &&
                <div>Filter < i className="fas fa-filter"></i></div>
            }
            <Modal zIndex="20001" isOpen={filterVisible} toggle={onShowFilterClick}>
                <ModalHeader className="bg-light">Filter Crappers</ModalHeader>
                <ModalBody>
                    <div className="card bg-light">
                        <div className="card-header">
                            By Rating:
                        </div>
                        <div className="card-body">
                            <Rating
                                rating={filterValues.MinRating}
                                canEdit={true}
                                onStarClick={onStarClick} />
                        </div>
                    </div>
                    <div className="card bg-light mt-3">
                        <div className="card-header">
                            By Amenity:
                        </div>
                        <div className="card-body">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Multistall"
                                    checked={filterValues.Multistall}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Multi-stall</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="SeparateMens"
                                    checked={filterValues.SeparateMens}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Separate Womens & Mens</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="AllGender"
                                    checked={filterValues.AllGender}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">All Gender</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="HandicapAccessible"
                                    checked={filterValues.HandicapAccessible}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Handicap Accessible</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="BabyChangeStation"
                                    checked={filterValues.BabyChangeStation}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Baby Changing Station</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Shower"
                                    checked={filterValues.Shower}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Shower</label>
                            </div>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="Fee"
                                    checked={filterValues.Fee}
                                    onChange={handleInputChange}>
                                </input>
                                <label className="form-check-label">Fee</label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around m-3">
                        <button className="btn btn-primary" onClick={onApplyFilterClick}>Apply</button>
                        <button className="btn btn-danger" onClick={onClearFilter}>Clear</button>
                    </div>              
                </ModalBody>
                <ModalFooter>
                    <div className="container">
                        <div className="d-flex justify-content-around">
                            <button className="btn btn-secondary" onClick={onShowFilterClick}>Close</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </button>

    );
}