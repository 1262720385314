import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from './authorization/UserProvider';
import { Poochievement } from './Poochievement';

export const Poochievements = (props) => {
    const { user } = useContext(UserContext);
    const [pooChievements, setPoochievements] = useState({})

    useEffect(() => {
        getPoochievements();
    }, []);

    const getPoochievements = async (/*args*/) => {
        const response = await fetch('api/poochievements');

        if (response.status == 200) {
            const data = await response?.json();
            setPoochievements(data);
        }
    }

    return (
        <div>
            {Object.values(pooChievements).map((pooChievement) =>
                <div key={pooChievement.type} className='text-center'>
                    <Poochievement type={pooChievement.type} />
                </div>
            )}
        </div>
    );
}