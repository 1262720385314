import React, { Component, useState, useContext } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row, Nav } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { UserContext } from './authorization/UserProvider';
import { NotificationButton } from './NotificationButton';

export const NavMenu = (props) => {
    const displayName = NavMenu.name;

    const { user } = useContext(UserContext);
    const isLoggedIn = user?.isLoggedIn ?? false;
    const [collapsed, setCollapsed] = useState(true);
    const [userMenuCollapsed, setUserMenuCollapsed] = useState(true);
    const isGuest = user != null && user.roles != null && user.roles.includes('Guest');
    const isAdmin = user != null && user.roles != null && user.roles.includes('Admin');

    const closeNavbar = () => {
        setCollapsed(true);
        setUserMenuCollapsed(true);
    }

    const toggleHamburgerMenu = () => {
        setCollapsed(!collapsed);
    }

    const toggleUserMenu = () => {
        setUserMenuCollapsed(!userMenuCollapsed);
    }

    return (
        <header id="divNavMenu">
        <Navbar className="border-bottom box-shadow navbar-dark navColor">
                <Container>
                    <div>
                        <Button style={{marginRight: '25px'}} className='navbar-toggler' color='none' onClick={toggleUserMenu}>
                            <UserIcon />
                        </Button>
                        <NotificationButton className='navbar-toggler' />
                    </div>
                <NavbarToggler onClick={toggleHamburgerMenu} className="mr-2" />
                <Container>
                    <Row>
                        <Col>
                            <Collapse isOpen={!userMenuCollapsed} navbar>
                                <Nav navbar>
                                    {isLoggedIn && !isGuest && (
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="text-white"
                                                to="/Profile"
                                                onClick={closeNavbar}>Profile
                                            </NavLink>
                                        </NavItem>                                 
                                    )}
                                    {isLoggedIn && !isGuest && (
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="text-white"
                                                to="/Poochievements"
                                                onClick={closeNavbar}>Poochievements
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {isLoggedIn && (
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="text-white"
                                                to="/authorization/Logout"
                                                onClick={closeNavbar}>{isGuest ? "Login" : "Logout"}
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {!isLoggedIn && (
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="text-white"
                                                to="/"
                                                onClick={closeNavbar}>Login
                                            </NavLink>
                                        </NavItem>)}
                                    {!isLoggedIn && (
                                        <NavItem>
                                            <NavLink
                                                tag={Link}
                                                className="text-white"
                                                to="/authorization/Register"
                                                onClick={closeNavbar}>Register
                                            </NavLink>
                                        </NavItem>)}
                                </Nav>
                            </Collapse>
                        </Col>
                        <Col>
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav style={{textAlign: 'right'}} navbar>
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-white"
                                        to="/"
                                        onClick={closeNavbar}>Map
                                    </NavLink>
                                </NavItem>
                                {isLoggedIn && (
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-white"
                                            to="/ReportBug"
                                            onClick={closeNavbar}>Report Bug
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-white"
                                        to="/Privacy"
                                        onClick={closeNavbar}>Privacy
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <Link
                                            className="text-white nav-link"                              
                                            to={{ pathname: "https://www.instagram.com/thecrapmap/"}}
                                            target="_blank"
                                            onClick={closeNavbar}>
                                            <i className="fa-brands fa-instagram"></i> Follow
                                        </Link>
                                    </NavItem>
                                {isAdmin &&
                                    <NavItem>
                                        <NavLink
                                            tag={Link}
                                            className="text-white"
                                            to="/authorization/UserManager"
                                            onClick={closeNavbar}>User Manager
                                        </NavLink>
                                    </NavItem>
                                }
                                </Nav>
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Navbar>
      </header>
    );
}

const UserIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 448 512" fill="#dee2e6ab">
            <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
        </svg>
      );
}
